import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
// import { ArchiveHistoryComponent } from './archive-history/archive-history.component';
import { PageComponent } from './page/page.component';
import { AboutZeewijkComponent } from './about-zeewijk/about-zeewijk.component';
import { AboutPelsaertComponent } from './about-pelsaert/about-pelsaert.component';
import { AboutVerguldeDraeckComponent } from './about-vergulde-draeck/about-vergulde-draeck.component';
import { AcknowledgementsComponent } from './acknowledgements/acknowledgements.component';
import { FileNotFoundComponent } from './file-not-found/file-not-found.component';

const routes: Routes = [
  // { path: '', redirectTo: 'journals/1/pages/0', pathMatch: 'full' }, //In the case of an empty path redirect to journal 1 page 0 
  { path: '', redirectTo: '/landing-page', pathMatch: 'full'},
  { path: 'landing-page', component: LandingPageComponent },
  // { path: 'archive-history', component: ArchiveHistoryComponent },
  { path: 'about-zeewijk', component: AboutZeewijkComponent },
  { path: 'about-pelsaert', component: AboutPelsaertComponent },
  { path: 'about-vergulde-draeck', component: AboutVerguldeDraeckComponent },
  { path: 'acknowledgements', component: AcknowledgementsComponent },
  { path: 'journals/:journalId/pages/:pageId', component: PageComponent},
  { path: '**', component: FileNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

/* -----------------------------------
Copyright:      Logical Developments 2021.
Project:        WA Museum, Batavia Project
Author:         Tristan Stephens
version:        0.06
Description: 	  Define routing
History:
0.06 23-06-23 PWM   (ld0011892) About X pages should still be available in the routes, otherwise we get a 404.
0.05 20-04-23 JRB (ld0010948) Added logic to go to File-Not-Found when an unhandled address is used.
0.04 23-04-21 PWM   (ld0010699) Added routine for About journals pages, acknowledgements
0.03 28-03-21 PWM   Added archive history link.
0.02 23-03-21 PWM   Added landing page as default.
0.01  ? JB Added default routing
0.00  ? TS  Created.
----------------------------------- */