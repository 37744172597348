import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageComponent } from './page/page.component';
import { PageImageComponent } from './page-image/page-image.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { FootNoteMarkupHtmlPipe} from './pipes/footNoteMarkupHtmlPipe';
import { HttpClientModule } from '@angular/common/http';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ArchiveHistoryComponent } from './archive-history/archive-history.component';
import { AboutZeewijkComponent } from './about-zeewijk/about-zeewijk.component';
import { AboutPelsaertComponent } from './about-pelsaert/about-pelsaert.component';
import { AboutVerguldeDraeckComponent } from './about-vergulde-draeck/about-vergulde-draeck.component';
import { AcknowledgementsComponent } from './acknowledgements/acknowledgements.component';
import { FileNotFoundComponent } from './file-not-found/file-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    PageComponent,
    PageImageComponent,
    FootNoteMarkupHtmlPipe,
    LandingPageComponent,
    ArchiveHistoryComponent,
    AboutZeewijkComponent,
    AboutPelsaertComponent,
    AboutVerguldeDraeckComponent,
    AcknowledgementsComponent,
    FileNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatListModule,
    MatCardModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
