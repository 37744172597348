import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from './domain/page';
import { MatListOption, MatSelectionList, MatList } from '@angular/material/list';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {
  private subscriber: any;

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router) { }

  @ViewChild('transcriptionList') transcriptionList: MatSelectionList;
  @ViewChild('transcriptionSideList') transcriptionSideList: MatList;
  @ViewChild('translationSideList') translationSideList: MatList;
  @ViewChild('translationList') translationList: MatList;

  page: Page = new Page();
  selectedTranscriptIndex: Number;

  footNoteColumns: string[] = ['fnnumber', 'fntext'];
  
  
  ngOnInit(): void {
    
    this.subscriber = this.route.params.subscribe(params => {
      this.http.get('/api/journals/' + params.journalId + '/pages/' + params.pageId).subscribe((data: Page) => {
        this.page = data;
        if (Object.keys(this.page).length === 0) { //Object.keys(obj).length returns the number of properties the object has. If 0 it must be undefined. We only get undefined when we put in an invalid address. https://stackoverflow.com/a/44338054/12672179
          this.router.navigate(['NotFound'],{skipLocationChange: true});
        } else { // Put this in an else block so we only replace the history state if we have a successful connection. Otherwise display last entered address.
        history.replaceState({},"","/journals/"+this.page.jpjnseq+"/pages/"+this.page.jppgref)
        }
      });
    });
  }



  onTranscriptionListChange(options: MatListOption[]) {
    this.selectedTranscriptIndex = this.page.transcripts.indexOf(options[0].value);
  }  

  onSelectionRectangleChanged(selectionIndex : number) : void {
    this.transcriptionList.deselectAll();
    //NOTE: angular does not trigger the onTranscriptionListChange event, so we manually set selectedTranscriptIndex to trigger the rectangle change 
    var selection = this.transcriptionList.options.toArray()[selectionIndex];
    this.transcriptionList.selectedOptions.select(selection);
    this.selectedTranscriptIndex = this.page.transcripts.indexOf(selection.value);
  }

  previous(): void {
    window.location.replace(this.page.prev); // Remove current page and load prev (equivalent to loading page then refreshing (slow/clunky))
    //this.router.navigate([this.page.prev]); // Can load every image if started from a page with an image but won't clear the image buffer)
  }
  next(): void {
    window.location.replace(this.page.next); // Remove current page and load prev (equivalent to loading page then refreshing (slow/clunky))
    //this.router.navigate([this.page.next]); // Can load every image if started from a page with an image but won't clear the image buffer)
  }

  onRefSelected(event): void {
    window.location.replace('journals/'+this.page.jpjnseq+'/pages/'+event.target.value) 
  }
}

/* -----------------------------------
Copyright:      Logical Developments 2023.
Project:        WA Museum, Batavia Project
Author:         Jordan Bock
version:        0.02
Description: 	  Define routing
History:
0.03 20-04-23 JRB (ld0010948) Added logic to go to File-Not-Found when we have an invalid reference
0.02 18-01-23 JRB (ld0011892) Removed header and online collections link from HTML
0.01  ? JB Massive modifications
0.00  ? TS  Created.
----------------------------------- */