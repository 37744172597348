import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Journal } from '../journal';               // Define structure of journal data.  Make it easier later on to reference.
// import { Observable } from 'rxjs';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
   allJournals: Journal[] = [];                    // Init array of available journals
   private subscriber: any;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    // load the list of journals.
    this.http.get<Journal[]>('/api/journals').subscribe(result => {this.allJournals = result});
  }

}

/* -----------------------------------
Copyright:      Logical Developments 2024.
Project:        WA Museum, Batavia Project
Author:         Aaron Muilne
version:        0.02
Description: 	  Define landing page with list of 
History:
0.02  18-01-23 JRB   (ld0011892) Removed Header, Footer and main body text. Changed list of journal links to a vertical mat-list
0.01  23-03-21 PWM   (ld0010620) Implemented dynamic list of journals
0.00  22-03-21 ADM   (ld0010620) Created.
----------------------------------- */
