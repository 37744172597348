import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-not-found',
  templateUrl: './file-not-found.component.html',
  styleUrls: ['./file-not-found.component.css']
})
export class FileNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

/* -----------------------------------
Copyright:      Logical Developments 2023.
Project:        WA Museum, Batavia Project
Author:         Jordan Bock
version:        0.01
Description: 	  File Not Found Message
History:
0.01 18-01-23 JRB (ld0011892) Created.
----------------------------------- */