<div class="landing">
  <!-- <header class="header clearfix" id="header" role="banner">
    <div
      class="flex--space-evenly"
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <a
        title="visit the Western Australian Museum website"
        href="http://museum.wa.gov.au"
        rel="home"
        class="headerlogos"
      >
        <img
          src="http://museum.wa.gov.au/online-collections/sites/all/themes/zen_collections/images/logos/gov_logo.png"
          class="headerlogos__logo--gov"
          alt="Australian Government Coat of Arms"
        />
        <img
          src="http://museum.wa.gov.au/online-collections/sites/all/themes/zen_collections/images/logos/wam_logo.png"
          class="headerlogos__logo--wam"
          alt="WAM"
        />
      </a>
      <a
        title="The Kingdom of the Netherlands"
        href="https://www.netherlandsandyou.nl/your-country-and-the-netherlands/australia/about-us/consulate-in-perth"
        class=""
      >
        <img
          src="../../assets/logo-kingdomOfTheNetherlands.png"
          alt="The logo of the Kindgom of the Netherlands, this takes you to the WA Consolate page of Netherlands and you in English"
          class="headerlogos__logo--Netherlands"
        />
      </a>
    </div>
  </header> -->

  <main class="main-landing container-landing">
    <h1>Pelsaert’s Journal</h1>
    <p>
      The retourship <i>Batavia</i> set out from Texel in the Netherlands on 28
      August 1628 as part of a fleet of six ships that sailed to the Indies.
      After a relatively uneventful trip, the ship arrived at the designated
      rendezvous with the rest of the fleet at the Cape of Good Hope. This would
      be the <i>Batavia’s</i> last landfall before it came to a catastrophic
      end.
    </p>
    <p>
      On the morning of the 4 June 1629, the <i>Batavia</i> was wrecked on the
      Houtman Abrolhos, off the coast of Western Australia. The shipwreck was a
      prelude to an ex¬traordinary tragedy.
    </p>
    <p>
      The commander, Francisco Pelsaert, all the senior officers, some crew and
      passengers (48 in all, including a baby), deserted the remaining 268
      people who were left on the ship and on two waterless islands nearby,
      whilst they ostensibly went in search of water. Abandoning this fruitless
      search on the mainland coast after 10 days, they then made their way to
      Batavia, to obtain help; taking, in all, 33 days to get there.
    </p>
    <p>
      On arrival, the high boatswain was executed, on Pelsaert's indictment, for
      outrageous behaviour onboard before the loss of the ship. The skipper,
      Adrien Jacobsz was arrested, again on Pelsaert's word, for negligence.
      Governor General Coen dispatched Pelsaert seven days later in the jacht
      <i>Sardam</i> to effect a rescue of the survivors. Blighted by
      extraordinary bad luck, the voyage to find the wreck site took 63 days,
      almost double the time it took the ship's boat to get to Batavia.
    </p>
    <p>
      On arrival at the wreck site, Pelsaert discovered that a mutiny and a
      terrible massacre had taken place. Led by the undermerchant, Jeronimus
      Cornelisz, a small group of mutineers had massacred 125 men, women and
      children. Pelsaert, after arresting the ringleaders, set up a court on the
      islands where the mutineers were tried and those found guilty, executed.
      Whilst the lengthy trials were underway, Pelsaert diligently set about
      recovering the chests of specie and other valuable items from the wreck,
      using divers from Gujarat.
    </p>
    <p>
      When the <i>Sardam</i> finally returned to Batavia, some of the other
      lesser offenders, who had been flogged, keelhauled and dropped from the
      yard arm as punishment on the voyage home, were executed.
    </p>
    <p class="flex-start">
      At the conclusion of the calamitous voyage, of 316 people who sailed
      aboard the <i>Batavia</i>, only 116 survived.
    </p>
    <p>
      Pelsaert kept a journal of his voyage from the Netherlands, but
      unfortunately that was thrown overboard by the drunken crew following the
      wreck. From then onwards his record of events as entered in his journal
      survived; the text here being what he wrote from the time of the loss;
      through the journey to Batavia in the ship’s boat and then back to the
      Abrolhos in the <i>Sardam</i>; the events in the Abrolhos, including the
      trial of the ringleaders; and, finally, his voyage back to Batavia. The
      pages of the Journal in the Nationaal Archief (State archives), however,
      are not from Pelsaert’s own handwritten journal, but are a copy of that
      journal made by the clerks in Batavia. In fact, multiple copies would have
      been made to be variously kept in Batavia and sent home on different
      ships.
    </p>
    <p class="flex-start">
      More information about
      <a
        href="http://museum.wa.gov.au/research/research-areas/maritime-archaeology/batavia-cape-inscription/batavia"
        >the <i>Batavia</i> story</a
      >
      and
      <a
        href="http://museum.wa.gov.au/maritime-archaeology-db/maritime-reports/loss-verenigde-oostindische-compagnie-retourschip-batavia-western-australia-1629"
        >the modern discovery and excavation</a
      >
      can be found on the Museum website.
    </p>

    <ul>
      <li><a href="/about-zeewijk">About the Zeewijk Journal</a></li>
      <li>
        <a href="/about-vergulde-draeck">About the Vergulde-Draeck Journal</a>
      </li>
    </ul>
    <p>
      <a href="/landing-page">Overview of the journals project</a>
    </p>
    <p>
      <a href="/acknowledgements">Project acknowledgements</a>
    </p>
    <h2>Available Journals</h2>
    <div class="journal-menu">
      <a
        *ngFor="let thisJournal of allJournals"
        routerLink="/journals/{{ thisJournal.jnseq }}/pages/0"
        aria-label="Choose a journal"
      >
        {{ thisJournal.jnname }}
      </a>
    </div>
  </main>

  <!-- <div class="collections-footer footer-landing">
    <a href="http://museum.wa.gov.au/online-collections"
      >Western Australian Museum Online Collections 2021</a
    >
  </div> -->
</div>
