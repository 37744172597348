<head>
  <title>Digital archive of shipwreck journals | WA Museum Collections</title>
</head>

<div id="page">
  <!-- <header>
    <div
      class="flex--space-evenly"
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <a
        title="visit the Western Australian Museum website"
        href="http://museum.wa.gov.au"
        rel="home"
        class="headerlogos"
      >
        <img
          src="http://museum.wa.gov.au/online-collections/sites/all/themes/zen_collections/images/logos/gov_logo.png"
          class="headerlogos__logo--gov"
          alt="Australian Government Coat of Arms"
        />
        <img
          src="http://museum.wa.gov.au/online-collections/sites/all/themes/zen_collections/images/logos/wam_logo.png"
          class="headerlogos__logo--wam"
          alt="WAM"
        />
      </a>
      <a
        title="The Kingdom of the Netherlands"
        href="https://www.netherlandsandyou.nl/your-country-and-the-netherlands/australia/about-us/consulate-in-perth"
        class=""
      >
        <img
          src="../../assets/logo-kingdomOfTheNetherlands.png"
          alt="The logo of the Kindgom of the Netherlands, this takes you to the WA Consolate page of Netherlands and you in English"
          class="headerlogos__logo--Netherlands"
        />
      </a>
    </div>
  </header> -->

  <!-- Start of Logical Developments Code -->
  <html>
    <div class="container-fluid mat-typography">
      <div class="row">
        <div class="col-sm-4">
          <!-- <h3 class="">{{page.jnlanguage}} Transcription</h3> -->
        </div>
        <div class="col-sm-4">
          <h1 class="text-center" style="margin-top: 2rem">
            {{ page.jnname }}, WA Museum
          </h1>
          <p class="subheading">{{ page.jppgref }}</p>
        </div>
        <div class="col-sm-4">
          <!-- <h3>English Translation</h3> -->
        </div>
      </div>
      <div class="row" style="margin: 0">
        <div
          class="col-sm-4"
          aria-label="The Dutch transcription of the journal"
        >
          <h2 class="text-center">{{ page.jnlanguage }} Transcription</h2>
          <mat-list
            #transcriptionSideList
            style="display:inline-block; vertical-align: top; width: {{
              page.transcriptPercent[0] + '%'
            }};"
          >
            <!-- Tell the list to display in the same line and with a set width to display next to the main text -->
            <!-- Errors are shown here but it works exactly as intended-->
            <mat-list-item
              *ngFor="let transcriptSide of page.transcriptsSide || []"
            >
              {{ transcriptSide }}
            </mat-list-item>
          </mat-list>
          <mat-selection-list
            #transcriptionList
            (selectionChange)="
              onTranscriptionListChange(
                transcriptionList.selectedOptions.selected
              )
            "
            [multiple]="false"
            style="display:inline-block; vertical-align: top; width: {{
              page.transcriptPercent[1] + '%'
            }};"
          >
            <!-- Errors are shown here but it works exactly as intended-->
            <mat-list-option
              *ngFor="let transcript of page.transcripts || []"
              [value]="transcript"
            >
              {{ transcript }} <br />
              <!-- Add a line break to the end of every line to account for empty lines -->
            </mat-list-option>
          </mat-selection-list>
        </div>
        <div class="col-sm-4">
          <p style="text-align: center">
            <a
              class="image-new-tab"
              href="{{ page.jpimgpathhigh }}"
              target="_blank"
              >View the journal image in a new tab
            </a>
          </p>
          <app-page-image
            id="myImage"
            [url]="page.jpimgpath"
            [imageDimensions]="page.jpboundrect"
            [selectedLineIndex]="selectedTranscriptIndex"
            [lineCount]="(page.transcripts || []).length"
            (onSelected)="onSelectionRectangleChanged($event)"
          >
          </app-page-image>
          <img
            id="imgHigh"
            width="1000"
            height="1000"
            src="{{ page.jpimgpathhigh }}"
            alt="test"
            style="display: none"
          />
          <button
            mat-fab
            class="previous"
            color="primary"
            aria-label="Previous"
            (click)="previous()"
            [disabled]="!page || !page.prev"
          >
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <button
            mat-fab
            class="next"
            color="primary"
            aria-label="Next"
            (click)="next()"
            [disabled]="!page || !page.next"
          >
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
        <div
          class="col-sm-4"
          aria-label="The English translation of the journal"
        >
          <h2 class="text-center">English Translation</h2>
          <script>
            var transSideWidth = page.translatePercent[0] + "%";
          </script>
          <script>
            var transMainWidth = page.translatePercent[1] + "%";
          </script>
          <span style="display: inline-flex; width: 100%">
            <!-- Create a span so we can display the side note next to the text-->
            <span
              [innerHTML]="page | footNoteMarkupHtml: page.jptranslateside"
              style="display: inline-block; vertical-align: top; width: {{
                page.translatePercent[0] + '%'
              }};"
            ></span>
            <!-- we need to align this to the top, hence why we need a parent span-->
            <span
              [innerHTML]="page | footNoteMarkupHtml: page.jptranslation"
              style="flex-basis: 55%; display: inline-block; vertical-align: top; width: {{
                page.translatePercent[1] + '%'
              }};"
            ></span>
            <!-- Errors are shown here but it works exactly as intended. Essentially CSS deems it invalid which is correct until the template is executed then the code becomes valid.-->
          </span>
          <!-- The ":translate" passes in the current paragraph while "page |" passes in the page object we are using-->
        </div>
      </div>
    </div>
    <!-- End of Logical Developments Code-->

    <div class="collections-footer">
      <!-- <a href="http://museum.wa.gov.au/online-collections"
        >Western Australian Museum Online Collections 2021</a
      >
      <br />
      <br /> -->
      <p>Page Navigation</p>
      <select
        #refList
        (change)="onRefSelected($event)"
        aria-label="Jump to a different page"
      >
        <option
          *ngFor="let ref of page.reflist || []"
          [value]="ref"
          [selected]="ref === page.jppgref"
        >
          {{ ref }}
        </option>
      </select>
    </div>
  </html>
</div>
